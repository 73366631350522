// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $expertly-web-primary: mat.define-palette(mat.$indigo-palette);
// $expertly-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $expertly-web-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $expertly-web-theme: mat.define-light-theme((
//   color: (
//     primary: $expertly-web-primary,
//     accent: $expertly-web-accent,
//     warn: $expertly-web-warn,
//   )
// ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($expertly-web-theme);

/* You can add global styles to this file, and also import other style files */
:root {
  --brand-accent-color: "";
  --brand-font-colort: "";
  --brand-font: "";

  --mat-select-panel-background-color: #fff;
  --mdc-radio-state-layer-size: 20px;
  --mat-autocomplete-background-color: #fff;

  --mdc-checkbox-unselected-focus-icon-color: #ebeced;
  --mdc-checkbox-unselected-icon-color: #ebeced;
  --mdc-checkbox-selected-focus-icon-color: #3D3D3E;
  --mdc-checkbox-selected-icon-color: #3D3D3E;
  --mdc-checkbox-selected-hover-icon-color: #3D3D3E;
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #0f0f10;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: #0f0f1033;
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: #0f0f1033;
  --mat-datepicker-calendar-date-hover-state-background-color: #0f0f1033;
  --mat-datepicker-calendar-date-in-range-state-background-color: #0f0f1033;
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: #0f0f1033;
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  // --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-full-pseudo-checkbox-selected-icon-color: #0f0f10;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
  --mdc-circular-progress-active-indicator-color: #0f0f10;

  --mdc-linear-progress-active-indicator-color: #5b41ff;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mat-option-selected-state-layer-color: #ebeced;
  --mat-tab-header-active-label-text-color: #0f0f10;
  --mdc-tab-indicator-active-indicator-color: #0f0f10;
  
  --mat-tab-header-label-text-size: 13px;

  --mdc-dialog-container-shape:12px;

  --mat-tab-header-divider-height: 0px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-size: 12px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}
ol{
  // display: block;
  list-style-type: decimal;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0;
  padding-right: 0;
  padding-left: 20px;
  li{
    padding-left: 5px;
  }
}
ul{
  // display: block;
  list-style-type: disc;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0;
  padding-right: 0;
  padding-left: 25px;
}
li:not(:last-child){
  padding-bottom: 12px;
}

p:empty{
  height: 16.5px;
}

.NgxEditor__Wrapper{
  border-color: #EBECED !important;
  // border-radius: 0.375rem !important;

}
.NgxEditor__Wrapper.disabled{
  border-color: #9CA3AF !important;
}
.NgxEditor__Popup--Form{
  font-size: 14px;
  input{
    border: 1.25px #EBECED solid;
    border-radius: 6px;
    padding-left: 4px;
  }
  button{
    border-radius: 6px;
    background-color: #0f0f10;
    color: white;
    padding: 5px;
    
  }
}
.NgxEditor__Content{
  &.NgxEditor__Content--Disabled{
    background-color: #EBECED;
    color: #9CA3AF;
  }
  min-height: 100px;
  font-size: 11px;
  p{
    margin: 0 !important;
  }
} 

.brand-font {
  font-family: var(--brand-font);
}

.mat-mdc-menu-panel {
  border-radius: 8px !important;
  border: 1px solid #ebeced !important;
  background: #fff !important;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.20) !important;
}
.mat-mdc-autocomplete-panel{
  border-radius: 8px !important;
  border: 1px solid #ebeced !important;
  background: #fff !important;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.20) !important;
}
.mat-mdc-tab-list {
  border-bottom: 1px solid #ebeced;
}

.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple) {
  .mat-pseudo-checkbox {
    display: none;
  }
}

.mat-mdc-input-element {
  font-weight: 500 !important;
}

.box-shadow {
  box-shadow: 0px 3.5px 0px 0px rgba(0, 0, 0, 0.05) !important;
}
.mat-expansion-panel {
  box-shadow: none !important;
  .mat-expansion-indicator::after {
    border-color: black !important;
  }
}

.mat-drawer-side {
  border: none !important;
}
.mat-mdc-menu-content{
  padding: 8px !important;
}
.pick-country {
  .mat-mdc-form-field {
    width: 100%;
  }
  .mat-mdc-form-field-flex {
    border: 1.25px solid #ebeced;
    border-radius: 6px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 12px;
    // font-weight: 500;
    line-height: 18px; /* 150% */
    letter-spacing: -0.12px;
  }
  .mat-form-field-invalid {
    position: relative;
    border: 1.25px solid #FF3D55B2;
    border-radius: 6px;
    &::after{
      content: "Required field*";
      color: #FF3D55;
      position: absolute;
      left: 0;
      bottom: -16px;
      font-size: 10px;
      font-weight: 500;
    }
  }

  input {
    padding: 13px 16px;
  }
  input::placeholder {
    color: #9ca3af !important;
  }
  .mdc-notched-outline {
    display: none;
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  
}
textarea {
  &:disabled {
    background-color: #ebeced;
    color: #9ca3af;
    border-color: #9ca3af;
  }
}
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}


.mat-expansion-toggle-indicator-after{
  .mat-expansion-indicator{
    transform: rotate(270deg) !important;
  }
  &.mat-expanded{
    .mat-expansion-indicator{
      transform: rotate(0deg) !important;
    }
  }
}
.mat-mdc-select-panel{
  border-radius: 8px !important;
  border: 1px solid #EBECED  !important;
  background: var(--Neutral-0, #FFF) !important;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.20) !important;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

p a{
  color: #5b41ff;
  text-decoration: underline;
}
.color-picker {
  .type-policy{
    display: none;
  }
  .hex-text .box:nth-child(2){
    display: none !important;
  }
}

@mixin flexible-radio-button($size, $touch-target-size, $padding: null) {

    .mdc-radio {
      @if ($padding) {
        padding: $padding;
        // default is `calc((var(--mdc-radio-state-layer-size) - 20px) / 2);`
      }

      --mdc-radio-state-layer-size: 2 * $size;
      height: $size;
      width: $size;
    }

    .mat-mdc-radio-touch-target {
      height: $touch-target-size;
      width: $touch-target-size;
    }

    .mdc-radio__background {
      height: $size;
      width: $size;
    }

    .mdc-radio__inner-circle {
      border-width: calc($size / 2);
    }
  
}

.small-radio-button {
  @include flexible-radio-button(14px, 16px, 2px);
}

.eContainer{
  width: 1400px;
  max-width: 1400px;
 padding-left: 80px;
 padding-right: 80px;
  margin-right: auto;
  margin-left: auto;
}